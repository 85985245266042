export const LANDING = "/";
export const PROFILE = "/profile";
export const ACCOUNT = "/account";
export const WELCOME = "/welcome";
export const ADMIN = "/admin";
export const SEARCH = "/search";
export const EXPLORE = "/explore";
export const PASSWORD_RESET = "/account/password-reset";
export const FEED = "/feed";
export const EXHIBITIONS = "/exhibitions";
export const USERNAME = "/:username";
export const USERNAME_CARD = "/:username/:cardTitle";
export const FOLLOWERS = "/:username/followers";
export const FOLLOWING = "/:username/following";
export const CARD = "/c/:cardID";
export const CATEGORY = "/categories/:category";

export const CATEGORIES = [
  "about_me",
  "art",
  "fashion",
  "fitness",
  "food",
  "fun_stuff",
  "music",
  "photography",
  "projects",
  "random",
  "stuff_i_like",
];
export const NON_USERNAMES = [
  "a",
  "add",
  "about",
  "acc",
  "account",
  "accounts",
  "activity",
  "all",
  "announcements",
  "anywhere",
  "api_rules",
  "api_terms",
  "apirules",
  "apps",
  "auth",
  "b",
  "badges",
  "blog",
  "business",
  "buttons",
  "c",
  "create",
  "contacts",
  "d",
  "delete",
  "devices",
  "direct_messages",
  "download",
  "downloads",
  "e",
  "edit_announcements",
  "exhibition",
  "exhibitions",
  "explore",
  "f",
  "faq",
  "favorites",
  "feed",
  "find_sources",
  "find_users",
  "followers",
  "following",
  "friend_request",
  "friendrequest",
  "friends",
  "g",
  "goodies",
  "h",
  "hello",
  "help",
  "home",
  "i",
  "ideagrate",
  "im_account",
  "inbox",
  "invitations",
  "invite",
  "j",
  "jobs",
  "k",
  "l",
  "list",
  "login",
  "logo",
  "logout",
  "m",
  "me",
  "mentions",
  "messages",
  "mockview",
  "n",
  "newonecase",
  "notifications",
  "nudge",
  "o",
  "oauth",
  "onecaseapp",
  "p",
  "phoenix_search",
  "positions",
  "privacy",
  "public_timeline",
  "q",
  "r",
  "replies",
  "rules",
  "s",
  "saved_searches",
  "search",
  "sent",
  "sessions",
  "settings",
  "share",
  "signup",
  "signin",
  "similar_to",
  "statistics",
  "terms",
  "t",
  "thankyou",
  "timeline",
  "tos",
  "translate",
  "trends",
  "u",
  "update",
  "update_discoverability",
  "user",
  "users",
  "v",
  "w",
  "welcome",
  "who_to_follow",
  "widgets",
  "x",
  "y",
  "z",
  "zendesk_auth",
  "media_signup",
];

// Card pages
